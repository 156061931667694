import React from 'react';
import { all } from 'q';


class ResultOverview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    openAllResults(){
        this.props.toggleAllResultsActive();
    }
    
    roundUpGrade() {
        return (
            Math.round(this.props.responses.grade * 10) / 10
        )
    }

    getGrade(grade) {
        if (grade === 'passed') {
            return "Staðið"
        } else {
            return "Fallið"
        }
    }

    getPassed (correct_answers) {
        if (correct_answers >= 46) {
            return "Staðið"
        } else {
            return "Fallið"
        }
    } 

    render() {
        const { responses, submitResponse } = this.props;
        return (
            <div className="results-overview">
                <div className="results-wrapper">
                {responses.older_exam &&
                    <div className="grade-percentage">
                        <div className="min-grade" style={{height: responses.min_grade * 10+'%'}}></div>
                        <div className="grade" style={{height: responses.grade * 10+'%'}}></div>
                    </div>
                }

                    <div className="grades">
                        {responses.older_exam ?
                        <>                        
                            <div>{gettext('Einkunn')} <span className="grade-number">{this.getGrade(responses.grade)}</span></div>
                            {submitResponse !== '' && <div>{submitResponse.wrong_answers.questions1+submitResponse.wrong_answers.questions2} villur í heildina.<br/>{submitResponse.wrong_answers.questions1} villur í hluta A. <br/>{submitResponse.wrong_answers.questions2} í hluta B.</div>}
                        </>
                        :
                        <>
                            <div className="grade-number">{this.getPassed(responses.correct_answers)}</div>
                            <div>{gettext('Einkunn')} <span className="grade-number">{responses.correct_answers}/50</span></div>
                            <div>{gettext('Lágmarkseinkunn')} 46/50 </div>
                        </>
                        }
                    </div>

                    <button className="btn button go-over" onClick={this.openAllResults.bind(this)}>{gettext('Fara yfir verkefnið')}</button>
                    <a className="button btn re-do" href={window.location.href}>{gettext('Endurtaka verkefnið')}</a>
                    
                </div>
            </div>
        )
    }
}

ResultOverview.defaultProps = {
}


export default ResultOverview;