import React from 'react';


class AllResults extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    isWholeQuestionCorrect(result) {
        let isQuestionCorrect = true;
        if (result.question_type == 'multi_choice_block') {
            result.answers.map((answer) => {
                if (!answer.answered_correctly) {
                    isQuestionCorrect = false
                }
            })
        } else if (result.question_type == 'true_false_block') {
            if (!result.answered_correctly) {
                isQuestionCorrect = false
            }
        }

        if (isQuestionCorrect) {
            return true
        } else {
            return false
        }
    }

    isQuestionPartiallyCorrect(result) {
        let answers = []
        if (result.question_type == 'multi_choice_block') {
            result.answers.map((answer) => {
                if (!answer.answered_correctly) {
                    answers.push(false)
                } else {
                    answers.push(true)
                }
            })
        } else if (result.question_type == 'true_false_block') {
            if (!result.answered_correctly) {
                answers.push(false)
            } else {
                answers.push(true)
            }
        }

        return !answers.every(a => a === true) && answers.some(a => a === true)
    }

    renderSingleMultiChoiceResult(result, isWholeQuestionCorrect) {
        if(isWholeQuestionCorrect) {
            return (
                <>
                    <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                        <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                        {result.correct_answer ? 
                            <label className="type">
                                 <span className="sr-only">Rétt</span>
                                {this.checkIcon()}
                            </label> : <label className="type"><span className="sr-only red-x">Rangt</span></label>}
                        <div>
                            {result.answer}
                        </div> 
                    </div>
                </>
            )
        } else {
            if (result.answered_correctly) {
                return (
                    <>
                        <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                            <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                            {result.correct_answer ? 
                            <label className="type">
                                <span className="sr-only">Rétt</span> 
                                {this.checkIcon()}
                            </label> : <label className="type"><span className="sr-only red-x">Rangt</span></label>}
                            <div className="correct-option">{result.answer}</div>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                            <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input>
                            {result.correct_answer ? <label className="type"><span className="sr-only">{gettext('Rétt')}</span></label> : 
                                <label className="type">
                                    <span className="sr-only red-x">Rangt</span>
                                    {this.checkIcon()}
                                </label>}
                            <div className="incorrect-option">
                                <div className="x"><span></span><span></span></div>
                                <div className="dots"><span></span><span></span><span></span></div>
                                {result.answer}
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    renderSingleTrueFalseResult(result) {
        if (result.answered_correctly) {
            return (
                <>
                    <div className="answer correct">
                        <div className="answer-container true">
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                                <label>{gettext('Rétt')}</label>
                            </div>
                        </div>
                        <div className="answer-container false">
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input>
                                <label>{gettext('Rangt')}</label>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="answer incorrect"> 
                        <div className={`answer-container true ${result.correct_answer == true ? "answer-true" : "answer-false"}`}>
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input><label>{gettext('Rétt')}</label>
                            </div>
                        </div> 
                        <div className={`answer-container false ${result.correct_answer == false ? "answer-true" : "answer-false"}`}>
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input><label>{gettext('Rangt')}</label>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    getResult(result) {
        let isWholeQuestionCorrect = this.isWholeQuestionCorrect(result) ? true : false
        if (result.question_type == 'multi_choice_block') {
            let allResults =
                result.answers.map((answer) => {
                    return this.renderSingleMultiChoiceResult(answer, isWholeQuestionCorrect)
                })
            return allResults
        } else if (result.question_type == 'true_false_block') {
            return this.renderSingleTrueFalseResult(result)
        }
    }

    checkIcon() {
        return(
            <svg width="25px" height="24px" viewBox="0 0 25 24">
                <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Verkefni-yfirferð" transform="translate(-339.000000, -931.000000)" fill="#34B843" fillRule="nonzero">
                        <polygon id="Path-Copy-2" points="347.57711 955 339 946.419531 342.206898 943.346994 347.132286 948.275173 360.415659 931 364 933.641877"></polygon>
                    </g>
                </g>
            </svg>
        )
    }

    triangleIcon() {
        return(
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 23">
            <defs/>
            <path fill="#ffd321" d="M28 23L14 0 0 23h28zM14 7l7 12H7l7-12z"/>
            </svg>
        )
    }

    closeIcon() {
        return(
            <svg width="22px" height="22px" viewBox="0 0 22 22">
                <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Verkefni-yfirferð" transform="translate(-341.000000, -1163.000000)" fill="#DD2121">
                        <path d="M355.142857,1174 L363,1181.85714 L359.857143,1185 L352,1177.14286 L344.142857,1185 L341,1181.85714 L348.857143,1174 L341,1166.14286 L344.142857,1163 L352,1170.85714 L359.857143,1163 L363,1166.14286 L355.142857,1174 Z" id="Combined-Shape-Copy"></path>
                    </g>
                </g>
            </svg>
        )
    }

    getAnswersContainerClasses(result) {
        let classes = "answers-container"
        if(result.question_type == 'true_false_block') {
            classes = classes + " radio"
        } else {
            classes = classes + " checkbox";
        }

        if(this.isWholeQuestionCorrect(result)) {
            classes = classes + " correct"
        } else {
            classes = classes + " incorrect"
        }
        return classes;
    }

    renderResults(results, wrongAnswers, older_exam) {
        let allResults = null;
        if(older_exam) {
            allResults = Object.keys(results).map((group) => {
                return([<h3>Hluti {group === 'questions1' ? 'A' : 'B'} ({group === 'questions1' ? wrongAnswers.questions1 : wrongAnswers.questions2} villur)</h3>, results[group].map((result,i) => {
                    return ([
                        <>
                        
                            <div className="results-container">
                            <h1 className="title">{gettext('Spurning')} {i + 1}</h1>
                            <span className="true-false">
                                {this.isQuestionPartiallyCorrect(result) ?
                                    <>
                                        {this.triangleIcon()}
                                    </>
                                    :
                                    <>
                                        {this.isWholeQuestionCorrect(result) ? this.checkIcon() : this.closeIcon() }
                                    </>
                            }
                            </span>
                            {result.image !== "" ? <div className="img-container"><img src={result.image}></img></div> : null}
                            <div className="question">{result.question}</div>
                            <div className={this.getAnswersContainerClasses(result)}>{this.getResult(result)}</div>
                        </div>
                        </>
                        
                    ])
                }
            )])
        })
        } else {
            allResults = [<h3>{wrongAnswers} villur</h3>, results.map((result, i) => {
                return([
                    <>
                    
                        <div className="results-container">
                        <h1 className="title">{gettext('Spurning')} {i + 1}</h1>
                        <span className="true-false">
                            {this.isQuestionPartiallyCorrect(result) ?
                                <>
                                    {this.triangleIcon()}
                                </>
                                :
                                <>
                                    {this.isWholeQuestionCorrect(result) ? this.checkIcon() : this.closeIcon() }
                                </>
                        }
                        </span>
                        {result.image !== "" ? <div className="img-container"><img src={result.image}></img></div> : null}
                        <div className="question">{result.question}</div>
                        <div className={this.getAnswersContainerClasses(result)}>{this.getResult(result)}</div>
                    </div>
                    </>
                    
                ])
            })]
        }
        return allResults
        // let allResults = Object.keys(results).map((group) => {
        //     return([<h3>Hluti {group === 'questions1' ? 'A' : 'B'} ({group === 'questions1' ? wrongAnswers.questions1 : wrongAnswers.questions2} villur)</h3>, results[group].map((result,i) => {
        //         return (
        //             <div className="results-container">
        //                 <h2 className="title">{gettext('Spurning')} {i + 1}</h2>
        //                 <span className="true-false">
        //                     {this.isQuestionPartiallyCorrect(result) ?
        //                         <>
        //                             {this.triangleIcon()}
        //                         </>
        //                         :
        //                         <>
        //                             {this.isWholeQuestionCorrect(result) ? this.checkIcon() : this.closeIcon() }
        //                         </>
        //                     }
        //                 </span>
        //                 {result.image !== "" ? <div className="img-container"><img src={result.image}></img></div> : null}
        //                 <div className="question">{result.question}</div>
        //                 <div className={this.getAnswersContainerClasses(result)}>{this.getResult(result)}</div>
        //             </div>
        //         )
        //     })])
        // })
        // return allResults
    }

    closeAllResults() {
        this.props.toggleAllResultsActive();
    }

    render() {
        const {results, wrongAnswers, older_exam } = this.props;
        return (
            <div className="all-results">
                {this.renderResults(results, wrongAnswers, older_exam)}
                <div className="results-overview-navigation">
                    <button className="button prev" onClick={this.closeAllResults.bind(this)}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                        </svg>
                    </button>
                    <a className="button btn re-do d-flex align-items-center jusitfy-content-center" href={window.location.href}>{gettext('Endurtaka verkefnið')}</a>
                </div>
            </div>
        )
    }
}

AllResults.defaultProps = {
}


export default AllResults;