import React from 'react';

import { answerTypes } from './actions'

const initialState = {
    isFetching: false,
    answerList: {'questions1': [], 'questions2': [], 'questions': []},
}



export function answers(state = initialState, action) {

    switch (action.type) {
        case answerTypes.ADD_ANSWER:
            state = { ...state }

            function addOrReplace(array, item) { 
                const i = array.findIndex(_item => _item.id === item.id);
                if (i > -1) array[i] = item;
                else array.push(item);
            }

            
            if(action.data.group !== null){
                addOrReplace(state.answerList[action.data.group], {id: action.data.key, value: action.data.value})
            }else{
                addOrReplace(state.answerList['questions'], {id: action.data.key, value: action.data.value})           
            }

            default:
            return state
    }
}