import React from "react";
import { fetchStudents } from '../../api/instructors-students/actions'
import { store } from '../../store'
import { connectWithStore } from '../../utils'

class StudentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  componentDidMount() {
    this.props.dispatch(fetchStudents())
  }

  getStudentTable(students) {
    if (students.length == 0) {
      return (<tr>Það eru engir nemendur sem tilheyra þér.</tr>)
    }

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Sími</th>
            <th>Tölvupóstur</th>
            <th>Heimilisfang</th>
            <th>Próf</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => {
            return (<tr>
              <td>{student.profile.full_name}</td>
              <td>{student.profile.telephone}</td>
              <td>{student.profile.email}</td>
              <td>{student.profile.address}, {student.profile.postalcode}</td>
              <td className="dropdown">
                <div className={`dropdown-toggle ${student.exams.length == 0 && 'no-tries'}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {student.exams.length == 0 && <div className="no-tries">Engar tilraunir</div>}
                  {student.exams.length == 1 && <div className="tries">{student.exams.length} tilraun</div>}
                  {student.exams.length > 1 && <div className="tries">{student.exams.length} tilraunir</div>}
                </div>
                <div className={`dropdown-menu ${student.exams.length == 0 && 'no-tries'}`} aria-labelledby="dropdownMenuButton">
                  {student.exams.map((e) => {
                      let date = new Date(e.created)
                      return(<div className="dropdown-item">{e.title && `${e.title} - `}{date.toLocaleDateString('is-IS')} - {e.passed ? 'Staðið' : 'Fallið'}{!e.passed && e.part_one_errors !== null && e.part_two_errors !== null && <><br />&nbsp;&mdash;&nbsp;Villur í fyrri hluta: {e.part_one_errors}<br />&nbsp;&mdash;&nbsp;Villur í seinni hluta: {e.part_two_errors}</>}</div>)
                    })}
                </div>
              </td>
            </tr>)
          })}
        </tbody>
      </table>
    )
  }

  render() {
    let { students } = this.props;
    return (
      students.data &&
      <div className="inner students-container">
        {this.getStudentTable(students.data)}
      </div>
    )
  }
}



function mapStateToProps(state) {
  var f = {
    students: {}
  }

  f.students = (state.students === undefined) ? [] : state.students

  return f;
}

export default connectWithStore(store, StudentsContainer, mapStateToProps);