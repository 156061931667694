import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');

import "../../sass/main.scss"

require('./views/driving-instructors')
require('./views/exam-page-container');
require('./views/instructors-students');
