import React from 'react';
import {SvgManual, SvgTrailer, SvgAutomatic, SvgMotorbike, SvgCheckedCheckbox, SvgEmptyCheckbox} from '../../components/svg'
import Slider from "react-slick";

class InstructorModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    renderImages(urls, settings) {
        if (urls) {
            return(<Slider {...settings}>
                {urls.map((url, index) => {
                    return(<div key={index}>
                        <img src={url.img}/>
                    </div>)
                })}
                </Slider>
            )
        }
    }

    renderTeachingCategories(d) {
        let teaches = ''
        if (d.bike) {
            teaches += 'mótorhjól,'
        }
        if (d.manual) {
            if(d.bike) {
                teaches += ' '
            }
            teaches += 'beinskipting,'
        }
        if (d.auto) {
            if(d.bike || d.manual) {
                teaches += ' '
            }
            teaches += 'sjálfskipting,'
        }
        if (d.trailer) {
            if(d.bike || d.manual || d.auto) {
                teaches += ' '
            }
            teaches += 'eftirvagn,'
        }
        teaches = teaches.substring(0, teaches.length - 1)
        return teaches
    }

    render() {
        const { id, instr } = this.props;
        const settings = {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          };
        return (
            <div className="modal aem instructor-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>Ökukennari</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex"><div className="info">Fullt nafn:</div>{instr.name}</div>
                            <div className="d-flex"><div className="info">Búseta: </div>{instr.address}, {instr.postcode}</div>
                            <div className="d-flex"><div className="info">Starfssvæði: </div>{instr.city}</div>
                            <div className="d-flex"><div className="info">Sími: </div><a href={`tel:${instr.telephone}`}>{instr.telephone}</a></div>
                            <div className="d-flex"><div className="info">Tölvupóstur: </div><a href={`mailto:${instr.email}`}>{instr.email}</a></div>
                            <div className="d-flex"><div className="info">Vefsíða: </div><a href={instr.website}>{instr.website}</a></div>
                            <div className="d-flex"><div className="info">Kennir á:</div>{this.renderTeachingCategories(instr)}</div>
                            {this.renderImages(instr.image_urls, settings)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


InstructorModal.defaultProps = {
}


export default InstructorModal;