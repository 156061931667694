import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const examTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'EXAMS');
export const submitAnswersTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SUBMIT_ANSWERS');
export const userExamTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'USER_EXAM');

export const requestExam = () => {
    return {
        type: examTypes.REQUEST,
    };
};

export const receiveExam = (response, query = {}) => {
    return {
        type: examTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveExamError = () => {
    return {
        type: examTypes.ERROR,
    };
};


export const fetchAllQuestions = (examNo) => {
    return dispatch => {
        dispatch(requestExam());
        
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        
        
        return fetch(`/api/v1/exam/` + examNo, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            
            try {
                dispatch(receiveExam(response));
            } catch (e) {
                dispatch(receiveExamError(response))
            }
            
            
        })
        .catch(handleNetworkError);
    }
}

export const requestSubmitAnswers = () => {
    return {
        type: submitAnswersTypes.REQUEST,
    };
};

export const receiveSubmitAnswers = (response, query = {}) => {
    return {
        type: submitAnswersTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveSubmitAnswersError = () => {
    return {
        type: submitAnswersTypes.ERROR,
    };
};

export const submitAnswers = (examNo, answers, older_exam) => {
    let answerList = null;
    if(older_exam){
        answerList = {questions1: [], questions2: []};
        delete answers['questions'];
        Object.keys(answers).map((group) => {
            answers[group].map((answer) => {
                answerList[group] = {...answerList[group], [answer.id]: answer.value}
            })
        })
    }else{
    answerList = {};
    answers['questions'].map((answer) => {
        answerList[answer.id]= answer.value
    })
}
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestSubmitAnswers());

        fetch(`/api/v1/exam/` + examNo + `/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(answerList),
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveSubmitAnswers(response));
                } catch (e) {
                    dispatch(receiveSubmitAnswersError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const requestUserExams = () => {
    return {
        type: userExamTypes.REQUEST,
    };
};

export const receiveUserExams = (response, query = {}) => {
    return {
        type: userExamTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveUserExamsError = () => {
    return {
        type: userExamTypes.ERROR,
    };
};

export const fetchUserExams = (exam_id) => {

    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestUserExams());

        fetch(`/api/v1/user_exam/` + exam_id + `/`, {
            method: 'GET',
            headers: headers,
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveUserExams(response));
                } catch (e) {
                    dispatch(receiveUserExamsError(response))
                }
            })
            .catch(handleNetworkError);
    }
}