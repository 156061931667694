import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'

export const studentTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'STUDENTS');


export const requestStudents = () => {
    return {
        type: studentTypes.REQUEST,
    };
};

export const receiveStudents = (response, key) => {
    return {
        type: studentTypes.RECEIVE,
        data: response,
    };
};

export const receiveStudentsError = () => {
    return {
        type: studentTypes.ERROR,
    };
};

export const fetchStudents = (search) => {
    return dispatch => {
        dispatch(requestStudents());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        
        let url = `/api/v1/instructors_students/`

        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveStudents(response));
            } catch (e) {
                dispatch(receiveStudentsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
