import React from 'react';

import { instructorTypes } from './actions'

export function instructors(state = {
    isFetching: false,
    data: null,
}, action) {

    switch (action.type) {
        case instructorTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state


        case instructorTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        default:
            return state
    }

}