import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'

export const instructorTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'INSTRUCTORS');


// export const parseJSON = response => {
//   try {
//     return response.json();
//   } catch (error) {
//     return response
//   }
// }

export const requestInstructors = () => {
    return {
        type: instructorTypes.REQUEST,
    };
};

export const receiveInstructors = (response, key) => {
    return {
        type: instructorTypes.RECEIVE,
        data: response,
    };
};

export const receiveInstructorsError = () => {
    return {
        type: instructorTypes.ERROR,
    };
};

export const fetchInstructors = (search) => {
    return dispatch => {
        dispatch(requestInstructors());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        
        let url = `/api/v1/driving_instructors/`
        if (search){
          console.log("Search: ", search)
          url += '?name=' + search
        }

        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveInstructors(response));
            } catch (e) {
                dispatch(receiveInstructorsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
