export const postalCodes = [
  {
      name: "Höfuðborgarsvæðið",
      postalCodes: [
          "101",
          "103",
          "104",
          "105",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "116",
          "162",
          "170",
          "200",
          "201",
          "203",
          "220",
          "221",
          "210",
          "225",
          "270",
          "271",
          "276",
      ]
  },
  {
      name: "Reykjanes",
      postalCodes: [
          "190",
          "191",
          "230",
          "232",
          "235",
          "240",
          "241",
          "245",
          "246",
          "250",
          "251",
          "260",
          "262"
      ]
  },
  {
      name: "Vesturland",
      postalCodes: [
        "300",
        "301",
        "302",
        "310",
        "311",
        "320",
        "340",
        "342",
        "345",
        "350",
        "355",
        "356",
        "360",
        "370",
        "371",
        "380"
      ]
  },
  {
      name: "Vestfirðir",
      postalCodes: [
        "400",
        "401",
        "410",
        "415",
        "420",
        "425",
        "430",
        "450",
        "451",
        "460",
        "465",
        "470",
        "471"
      ]
  },
  {
      name: "Norðvesturland",
      postalCodes: [
        "500",
        "510",
        "512",
        "520",
        "524",
        "530",
        "531",
        "540",
        "541",
        "545",
        "550",
        "551",
        "560",
        "565",
        "566",
        "570",
        "580",
      ]
  },
  {
      name: "Norðausturland",
      postalCodes: [
        "600",
        "601",
        "602",
        "603",
        "604",
        "605",
        "606",
        "607",
        "610",
        "611",
        "620",
        "621",
        "625",
        "630",
        "640",
        "641",
        "645",
        "650",
        "660",
        "670",
        "671",
        "675",
        "680",
        "681",
        "685",
        "690",
      ]
  },
  {
      name: "Austurland",
      postalCodes: [
        "700",
        "701",
        "710",
        "715",
        "720",
        "730",
        "735",
        "740",
        "750",
        "755",
        "760",
        "765",
      ]
  },
  {
      name: "Suðausturland",
      postalCodes: [
        "780",
        "781",
        "785",
      ]
  },
  {
      name: "Suðurland",
      postalCodes: [
        "800",
        "801",
        "802",
        "803",
        "804",
        "805",
        "806",
        "810",
        "815",
        "816",
        "820",
        "825",
        "840",
        "845",
        "850",
        "851",
        "860",
        "861",
        "870",
        "871",
        "880",
      ]
  },
  {
      name: "Vestmannaeyjar",
      postalCodes: [
        "900"
      ]
  },
]

export const licenceTypes = {
  bike: "Bifhjól",
  auto: "Sjálfskiptur",
  manual: "Beinskiptur",
  trailer: "Með kerru"
}

export const icelandic_alphabet = ["A", "Á", "B", "D", "Ð", "E", "É", "F", "G", "H", "I", "Í", "J", "K", "L", "M", "N", "O", "Ó", "P", "R", "S", "T", "U", "Ú", "V", "X", "Y", "Ý", "Þ", "Æ", "Ö"]