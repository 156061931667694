import React from 'react';
import { connect } from 'react-redux';


export const parseJSON = response => {
  try {
    return response.json();
  } catch (error) {
    return response
  }
}

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error)
  }
}

export const Spinner = (props) => <i className="spinner fa fa-spinner fa-pulse"></i>;


export const connectWithStore = (store, WrappedComponent, ...args) => {
  var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function (props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const formatKennitala = (value) => {
  return `${value.substring(0, 6)}-${value.substring(6, 10)}`
}

export const getUrlVars = () => {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}


// export const formatMoney = (num) => {
//   return accounting.formatMoney(+num, '', 2, ".", ",")
// }

export const getUrlParam = (parameter, defaultvalue) => {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

export const getPrettierDateFormat = (date) => {
  let prettyDate = "";
  let splitDate = date.split("/")
  let monthName = "";
  if (splitDate[1] == 1) {
    monthName = "janúar"
  } else if (splitDate[1] == 2) {
    monthName = "febrúar"
  } else if (splitDate[1] == 3) {
    monthName = "mars"
  } else if (splitDate[1] == 4) {
    monthName = "apríl"
  } else if (splitDate[1] == 5) {
    monthName = "maí"
  } else if (splitDate[1] == 6) {
    monthName = "júní"
  } else if (splitDate[1] == 7) {
    monthName = "júlí"
  } else if (splitDate[1] == 8) {
    monthName = "ágúst"
  } else if (splitDate[1] == 9) {
    monthName = "september"
  } else if (splitDate[1] == 10) {
    monthName = "október"
  } else if (splitDate[1] == 11) {
    monthName = "nóvember"
  } else {
    monthName = "desember"
  }

  let day = "";
  if (splitDate[0].startsWith("0")) {
    day = splitDate[0].slice(1)
  } else {
    day = splitDate[0]
  }
  prettyDate = day + ". " + monthName;
  return prettyDate
}

export const getWeekDay = (date) => {
  let splitDate = date.split("/")
  let day = new Date(splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] + "T00:00:00Z").getDay();
  let dayName = "";
  if (day == 0) {
    dayName = "sunnudaginn"
  } else if (day == 1) {
    dayName = "mánudaginn"
  } else if (day == 2) {
    dayName = "þriðjudaginn"
  } else if (day == 3) {
    dayName = "miðvikudaginn"
  } else if (day == 4) {
    dayName = "fimmtudaginn"
  } else if (day == 5) {
    dayName = "föstudaginn"
  } else if (day == 6) {
    dayName = "laugardaginn"
  }
  return dayName;
}

export const currencyFormatter = (amount) => {
  let formattedAmount = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

  return formattedAmount
}
