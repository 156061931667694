import React from 'react';

import { examTypes, submitAnswersTypes, userExamTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
    submitResponse: ""
}



export function exams(state = initialState, action) {


    switch (action.type) {

        case examTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state

        case examTypes.RECEIVE:
            state = { ...state, isFetching: false }
            if(action.data.older_exam){
                state.results.questions1 = action.data.questions1
                state.results.questions2 = action.data.questions2
            }else{
                state.results = action.data.questions
            }
            state.extraInformation = {
                'title': action.data.title,
                'read_again_link': action.data.read_again_link,
                'next_page_link': action.data.next_page_link,
                'description': action.data.description,
                'older_exam': action.data.older_exam,
            }

            return state

        case submitAnswersTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state

        case submitAnswersTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.submitResponse = action.data

            return state

        case userExamTypes.REQUEST:
            state = { ...state, isFetchingUserExams: true, }
            state.isFetching = true
            
            return state

        case userExamTypes.RECEIVE:
            state = { ...state, isFetchingUserExams: false }
            let data = action.data
            let total = data.length
            let passed = 0
            let not_passed = 0

            data.map((userExam) => {
                if (userExam.passed) {
                    passed += 1
                } else {
                    not_passed += 1
                }
            })

            state.userExam = {
                'total': total,
                'passed': passed,
                'not_passed': not_passed
            }

            return state

        default:
            return state
    }
}
