import React from "react";
import { fetchInstructors } from '../../api/driving-instructors/actions'
import { store } from '../../store'
import { connectWithStore } from '../../utils'
import { postalCodes, licenceTypes, icelandic_alphabet } from '../../constants'
import {SvgManual, SvgTrailer, SvgAutomatic, SvgMotorbike, SvgCheckedCheckbox, SvgEmptyCheckbox} from '../../components/svg'
import InstructorModal from '../../components/instructor-modal'

class InstructorsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      selectedArea: '',
      areaPostalCodes: '',
      licenceType: '',
      languageType: '',
    }

    this.getInstructorTable = this.getInstructorTable.bind(this)
    this.sortDataByLetter = this.sortDataByLetter.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getAreaDropdown = this.getAreaDropdown.bind(this)
    this.getLicenceTypeDropdown = this.getLicenceTypeDropdown.bind(this)
    this.getLanguageDropdown = this.getLanguageDropdown.bind(this)
  }

  componentDidMount(){
    this.props.dispatch(fetchInstructors())
  }

  sortByIcelandicAlphabet(a, b){
    const firstA = a.name[0].toUpperCase();
    const firstB = b.name[0].toUpperCase();

    if(icelandic_alphabet.indexOf(firstA) < icelandic_alphabet.indexOf(firstB)){
        return -1
    }
    if(icelandic_alphabet.indexOf(firstA) > icelandic_alphabet.indexOf(firstB)){
        return 1
    }
    return 0
  }

  // Returns an array sorted alphabetically, with each object containing
  // two attributes: the letter and an array of instructor objects
  sortDataByLetter(data){
    // Sort by icelandic alphabet to get list in correct order
    data.sort(this.sortByIcelandicAlphabet)

    // If user has selected an area, filter out driving instructors by postcode
    if(this.state.selectedArea){
      data = data.filter(teacher => this.state.areaPostalCodes.includes(teacher.postcode))
    }
    // If user has selected a licence type, filter out by instructors
    if(this.state.licenceType){
      data = data.filter(teacher => teacher[this.state.licenceType])
    }

    if (this.state.languageType) {
      data = data.filter(teacher => teacher.teaching_languages.filter(lang => lang.shortcode === this.state.languageType).length > 0)
    }

    let new_data = data.reduce((r, e) => {
      let letter = e.name[0];                                   // get first letter of name of current element
      if (!r[letter]) r[letter] = { letter, instructors: [e] }  // if there is no property in accumulator with this letter create it
      else r[letter].instructors.push(e);                       // if there is push current element to children array for that letter
      return r;                                                 // return accumulator
    }, {});
    
    let result = Object.values(new_data); 

    return result
  }

  getAreaDropdown(){
    return (
      <>
      <div className="filter-container d-flex flex-column">
        <label className="filter" htmlFor="area">Landshluti:</label>
        <select name="selectedArea" id="area" onChange={(e) => this.handleChange(e)}> 
          <option className="first-option" value="">Allt</option>  
          { postalCodes.map((i) => {
            return <option value={i.name}>{ i.name }</option>  
          })
        }
        </select>
        </div>
      </>
    )
  }

  getLicenceTypeDropdown(){
    return (
      <>
      <div className="filter-container d-flex flex-column">
        <label className="filter" htmlFor="licence">Tegund kennslu:</label>
        <select name="licenceType" id="licence" onChange={(e) => this.handleChange(e)}> 
          <option value="">Allt</option>  
          { Object.entries(licenceTypes).map(([key, value]) =>  {
              return <option value={key}>{ value }</option>  
            })
          }
        </select>
        </div>
      </>
    )
  }

  getLanguageDropdown(){
    if (this.props.instructors && this.props.instructors.data) {
      let languages = []
      this.props.instructors.data.map(instructor => {languages=languages.concat(instructor.teaching_languages)})
      const getUniqueArray = (array) => {
          return array.filter((elem, index) => array.findIndex(obj => obj.shortcode == elem.shortcode) === index);
      }
      languages = getUniqueArray(languages)

      return (
        <>
        <div className="filter-container d-flex flex-column">
          <label className="filter" htmlFor="language">Tungumál:</label>
          <select name="languageType" id="language" onChange={(e) => this.handleChange(e)}> 
            <option value="">Allt</option>  
            { languages.map(lang =>  {
                return <option value={lang.shortcode}>{ lang.title }</option>
              })
            }
          </select>
          </div>
        </>
      )
    }
  }

  getInstructorTable(input){
    var collator = new Intl.Collator('is', {
      numeric: true,
      sensitivity: 'base'
    });
    let sorted = input.sort(function(a, b) {
      return collator.compare(a.name, b.name)
    });
    let data = this.sortDataByLetter(sorted)

    return (
      <>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th className="name">Nafn</th>
              <th className="d-none d-md-table-cell living">Búseta</th>
              <th className="d-none d-md-table-cell location">Starfssvæði</th>
              <th className="d-none d-md-table-cell phone">Sími</th>
              <th className="languages">Tungumál</th>
              <th className="motrobike w-6"><SvgMotorbike /><span className="tooltiptext d-none d-md-block">Mótorhjól</span></th>
              <th className="manual w-6"><SvgManual /><span className="tooltiptext d-none d-md-block">Beinskipting</span></th>
              <th className="automatic w-6"><SvgAutomatic /><span className="tooltiptext d-none d-md-block">Sjálfskipting</span></th>
              <th className="trailer"><SvgTrailer /><span className="tooltiptext d-none d-md-block">Eftirvagn</span></th>
            </tr>
          </thead>
          <tbody>    
            { data.map((item, i) => {
              return (
                <>
                <tr className="first-row">
                    <td colSpan="8"><h3>{item.letter}</h3></td>
                </tr>
                { item.instructors.map((instr, idx) => {
                  return (
                    <tr>
                        <td className="name" data-toggle="modal" data-target={`#instructor-modal-${i}-${idx}`}>{instr.name}</td>
                        <td className="d-none d-md-table-cell">{instr.postcode}</td>
                        <td className="d-none d-md-table-cell">{instr.city}</td>
                        <td className="d-none d-md-table-cell"><a href={`tel:${instr.telephone}`}>{instr.telephone}</a></td>
                        <td className="languages">{instr.teaching_languages.map((lang) => lang.shortcode.toUpperCase()).join(", ")}</td>
                        <td>{instr.bike ? <><SvgCheckedCheckbox /></> : <SvgEmptyCheckbox />}</td>
                        <td>{instr.manual ? <><SvgCheckedCheckbox /></> : <SvgEmptyCheckbox />}</td>
                        <td>{instr.auto ? <><SvgCheckedCheckbox /></>: <SvgEmptyCheckbox />}</td>
                        <td>{instr.trailer ? <><SvgCheckedCheckbox /></>: <SvgEmptyCheckbox />}</td>
                      </tr>
                    )
                  })}
              </>
              )
            })
          }
          </tbody>
        </table>
      </div>
      {data.map((item, i) => {
        return(item.instructors.map((instr, idx) => {
          return(<InstructorModal id={`instructor-modal-${i}-${idx}`} instr={instr} key={`${i}-${idx}`}/>
          )
        }))})}
      </>
    )
  }

  handleChange(e){
    // console.log("Target name: ", e.target.name)
    this.setState({ [e.target.name]: e.target.value })
    if(e.target.value === ''){
      this.props.dispatch(fetchInstructors())  
    }
    if(e.target.name === 'selectedArea'){
      if(e.target.value !== ''){
        // Get postal codes from name attribute
        var result = postalCodes.filter(obj => {
          return obj.name === e.target.value
        })
        this.setState({
          areaPostalCodes: result[0].postalCodes
        })
      } 
    }
  }

  onFormSubmit(e){
    e.preventDefault()
    this.props.dispatch(fetchInstructors(this.state.searchValue))
  }

  render(){
    let { instructors } = this.props;
    return(
      <>
      <div className="d-flex flex-column flex-md-row top-container">
        { this.getAreaDropdown() }
        { this.getLicenceTypeDropdown() }
        { this.getLanguageDropdown() }
        <form className="d-flex align-items-end" action="#" onSubmit={this.onFormSubmit}>
            <input onChange={this.handleChange} type="text" name="searchValue" id="name"/>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0C2.693 0 0 2.693 0 6C0 9.306 2.693 12 6 12C7.38347 12.0014 8.72437 11.5216 9.793 10.643L12.974 13.824C13.0868 13.9365 13.2397 13.9996 13.399 13.9996C13.5583 13.9996 13.7112 13.9365 13.824 13.824C13.8796 13.7688 13.9237 13.7031 13.9538 13.6308C13.984 13.5584 13.9995 13.4809 13.9995 13.4025C13.9995 13.3241 13.984 13.2466 13.9538 13.1742C13.9237 13.1019 13.8796 13.0362 13.824 12.981L10.644 9.799C11.521 8.72729 11.9994 7.38479 11.998 6C12 2.694 9.306 0 6 0ZM6 1C8.769 1 11 3.231 11 6C11 8.769 8.769 11 6 11C5.34302 11.0013 4.69225 10.8729 4.08502 10.6221C3.4778 10.3713 2.92608 10.003 2.46153 9.53847C1.99697 9.07392 1.62872 8.5222 1.37792 7.91498C1.12711 7.30775 0.998682 6.65698 1 6C1 3.231 3.231 1 6 1Z" fill="#464849"/>
            </svg>
            {/* <input type="submit" value="Submit"/> */}
        </form>      
      </div>
      { instructors.data && 
        <div className="inner">
          { this.getInstructorTable(instructors.data) }
        </div>
      }
      </>
    )
  }
}



function mapStateToProps(state) {
  var f = {
      instructors: {}
  }

  f.instructors = (state.instructors === undefined) ? [] : state.instructors

  return f;
}

export default connectWithStore(store, InstructorsContainer, mapStateToProps);