import React from 'react';

import { studentTypes } from './actions'

export function students(state = {
    isFetching: false,
    data: null,
}, action) {

    switch (action.type) {
        case studentTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state


        case studentTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        default:
            return state
    }

}